:root {
  --white-color: #fff;
  --primary-color: #105093;
  --border-color: #ccc;
  --border-radius: 5px;
  --input-width: 150px;
  --input-height: 30px;
  --button-width: 100px;
  --button-height: 42px;
  --icon-left: 165px;
}

body {
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

form {
  flex-direction: column;
  display: flex;
}

.hidden {
  display: none;
}

.input-container {
  width: 320px;
  display: flex;
  position: relative;
}

input {
  width: var(--input-width);
  height: var(--input-height);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  padding: var(--border-radius) 40px var(--border-radius) 10px;
  border-right: none;
  outline: none;
  margin-bottom: 10px;
}

button {
  box-shadow: none;
  background: none;
  border: none;
  outline: none;
}

.eye-icon {
  top: 10px;
  left: var(--icon-left);
  cursor: pointer;
  position: absolute;
}

.submit-button {
  width: var(--button-width);
  height: var(--button-height);
  border: 1px solid var(--border-color);
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  background-color: var(--primary-color);
  cursor: pointer;
  color: var(--white-color);
  font-size: 14px;
  font-weight: bold;
}

/*# sourceMappingURL=index.21799d1d.css.map */
